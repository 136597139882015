<template>
  <div class="information">
    <el-form size="mini" ref="form" :model="form" label-width="80px">
      <el-form-item label="姓名: ">
        <el-input disabled v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="账号: ">
        <el-input disabled v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="医院: ">
        <el-input disabled v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="科室: ">
        <el-input disabled v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="职称: ">
        <el-input disabled v-model="form.account"></el-input>
      </el-form-item>
      <el-form-item label="简介: ">
        <el-input disabled v-model="form.account" type="textarea" rows="4"></el-input>
      </el-form-item>
      <el-form-item label="擅长: ">
        <el-input disabled v-model="form.account" type="textarea" rows="4"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="goBack">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {

      }
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.information {
  width: 400px;
}
</style>
